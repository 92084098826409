// Here you can add other styles
body {
  font-family: Poppins, Helvetica, sans-serif;
}

.react-toast-notifications__container {
  margin-top: 50px;
}

.table > :not(caption) > * > * {
  box-shadow: unset;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 21, 0.05);
}

.table-part-row {
  line-height: 0;
  transition: 1s;
  opacity: 0;
  padding: 0 !important;
}

.p-0:hover {
  background-color: #fff;
}

.table-part-row.show {
  line-height: 1em;
  transition: 1s;
  opacity: 1;
  padding: auto !important;
}

.c-sidebar-brand {
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  padding-left: 12px;
  justify-content: normal;
}

.stretch-input {
  flex: 1 1 auto;
}

.c-avatar {
  font-size: 35px;
}

.user-avatar {
  border-radius: 50%;
  width: 100px;
}

/*************** LOADER **/

/** CSS for animated bouncing loader. */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.s-load {
  justify-content: start;
  margin-top: 15px;
}

.loader.l-load {
  justify-content: start;
  margin-top: 15px;
}

/** Loader circles **/
.loader > span {
  background: #fad000;
  border-radius: 50%;
  margin: 5rem 0.5rem;
  animation: bouncingLoader 0.6s infinite alternate;
}

.loader.s-load > span {
  margin: 0.1rem 0.5rem;
  animation: sm-bouncingLoader 0.6s infinite alternate;
}

.loader.l-load > span {
  margin: 0.1rem 0.5rem;
}

.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes sm-bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 0.6rem;
    height: 0.6rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
/*************** END LOADER **/
.card-title {
  font-weight: bold;
  font-size: 16px;
}

.card-header {
  padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.context-menu {
  display: inline;
}

.context-menu button {
  padding: 1px 1px 1px 3px;
}

.form-control-sm {
  font-size: 0.5em;
}

.form-err {
  font-size: 0.875rem;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.form-check label {
  margin-left: 10px;
}

.form-check-input {
  margin-top: 0;
}

.drag-area {
  font-size: 1.25rem;
  background-color: #c8dadf;
  position: relative;
  padding: 30px 20px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
}

.drag-area-icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px;
}

.toolbar-sm {
  padding: 0.2rem 0.25rem;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.dnd-container {
  justify-content: space-around;
}

.dnd-container > .column {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.nav-link {
  color: #1136a8;
}

.dnd-container > .column > .header {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 10px;
  display: block;
  text-align: center;
}

.dnd-container .movable-item {
  background-color: #fff3f3;
  margin: 10px auto;
  padding: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

/** Deal View **/

.company-logo > img {
  width: 160px;
  height: 160px;
  max-width: 160px;
  max-height: 160px;
  border-radius: 0.475rem;
}

.thumb-logo {
  border-radius: 0.25rem;
  width: 40px;
  height: 40px;
}

.thumb-logo-spinner {
  text-align: center;
  padding-top: 5px;
}

.thumb-logo-lg {
  border-radius: 0.25rem;
  width: 160px;
  height: 160px;
}

.thumb-logo-empty {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  padding-top: 2px;
}

.thumb-logo-empty-lg {
  font-size: 95px;
  font-weight: 600;
  text-align: center;
  padding-top: 7px;
}

.thumb-logo-spinner-lg {
  text-align: center;
  padding-top: 60px;
}

.deal .flex-grow-1 {
  flex-grow: 1 !important;
}

.deal .badge {
  padding: 0.25em 0.4em;
}

.text-hover-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.deal .stat-block {
  border: 2px solid #eff2f5 !important;
  border-color: #e4e6ef;
  border-radius: 0.475rem;
  margin-right: 1rem;
  min-width: 110px;
  padding: 0.75rem;
}

.deal .btn-group button {
  border: 0;
  color: #768192;
  font-weight: 700;
  font-size: 16px;
  padding: 0.5rem 0;
  margin: 0;
  min-width: 115px;
  transition: none;
}

.deal .btn-group button.active {
  border: 0;
  border-bottom: 3px solid #0c277a;
  border-radius: 0;
}

.deal .btn-group button:hover {
  color: #1136a8;
}

.card-header-sm {
  padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.force-wrap {
  display: block;
  word-wrap: break-word;
  white-space: normal;
}

.company-card {
  min-height: 205px;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 36px;
  width: 5px;
  top: 70px;
  bottom: 40px;
  background-color: #eff2f5;
}

.timeline .item-label {
  width: 95%;
  font-size: 15px;
}

.timeline .timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.7rem;
  margin-left: 10px;
}

.timeline .timeline-item .badge-pill {
  border-radius: 10px;
  font-size: 14px;
  min-width: 33px;
  border: 4px solid #ebedef;
}

.timeline .timeline-badge {
  flex-shrink: 0;
  background: #fff;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: flex;
  font-size: 1.75rem !important;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: 0;
  padding: 3px !important;
  border: 6px solid #fff !important;
}

.timeline .deal-user {
  margin-left: 43px;
}

.timeline .deal-activity {
  justify-content: center;
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 100px;
  }
}

.c-sidebar.c-sidebar-sm {
  width: 100px;
}

.c-sidebar-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-sidebar-nav-link svg {
  font-size: 25px;
}

.sidebar-divider {
  border-top: 3px solid rgba(0, 0, 0, 0.3);
}

.center-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deal-filter {
  display: flex;
  justify-content: flex-end;
}

.of-counter {
  color: #4f5d73;
  background-color: #ebedef;
  border-color: #ebedef;
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
}

.deal-active-menu > a {
  background: rgba(255, 255, 255, 0.05) !important;
}

.editable {
  border-bottom: 1px dashed #ccc;
  display: inline;
  width: fit-content;
}

.editable:hover {
  border: 1px dashed #ccc;
}

.editable .help-block {
  visibility: hidden;
}

.editable:hover .help-block {
  visibility: visible;
}

.loader-overlay .help-block {
  display: none;
}

.loader-overlay:hover .help-block {
  display: block;
}

.edit-box {
  width: 100px;
}

.inline-err-msg {
  max-width: 150px;
  clear: both;
}

.inline-icon:hover {
  font-size: 15px;
  color: #1136a8;
}

.centered {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.table th,
.table td {
  vertical-align: middle;
}

.k-board {
  font-family: sans-serif;
  white-space: nowrap;
  display: flex;
  flex: 1 1 auto;
}

.k-column {
  display: flex;
  flex-direction: column;
  align-self: auto;
  flex: 1 1 auto;
  vertical-align: top;
  min-width: 240px;
  max-width: 240px;
  padding: 0;
  border-radius: 5px;
  margin-right: 10px;
  color: #bfdbf7;
  background-color: rgb(244, 245, 247);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.k-column-title {
  font-style: inherit;
  line-height: 1.14286;
  padding: 12px 5px 12px 0px;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: #3c4b64;
  letter-spacing: normal;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: rgb(244, 245, 247);
}

.k-column-title-lbl {
  padding-left: 15px;
  overflow: hidden;
  max-width: 165px;
}
.k-column-title-cnt {
  padding-right: 4px;
  font-size: 12px;
}

.k-card-title {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
}

.k-card-title a {
  display: block;
  padding-left: 0;
  padding-top: 10px;
  line-height: 15px;
  color: #3c4b64;
}

.k-card-header {
  display: flex;
}

.k-card-footer {
  display: flex;
}

.k-card-footer span {
  flex: auto;
  margin: 0;
  text-align: center;
}

.k-card {
  padding: 10px 10px 10px 5px;
  max-width: 270px;
  cursor: grab;
  user-select: none;
  box-sizing: border-box;
  position: relative;
  margin: 10px 10px 10px 10px;
  border-left-width: 5px;
  border-left-style: solid;
  box-shadow: #4f6384 0px 1px 1px, #4f6384 0px 0px 1px;
  transition: background-color 140ms ease-in-out 0s, color 140ms ease-in-out 0s;
  background-color: #ffffff;
  color: #3c4b64;
  filter: none;
  border-radius: 0.25rem;
}

.k-card--spacer,
.k-card--dragging {
  opacity: 0;
  cursor: default;
}

.k-section {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  user-select: none;
  width: fit-content;
}

.k-scroll-board {
  max-height: 73vh;
  height: 100%;
  overflow: auto;
  flex: 1 1 0%;
  position: relative;
  overflow: auto;
}

.k-board-wrapper {
  min-width: 0px;
}

.icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.nav-link:hover {
  color: rgba(44, 56, 74, 0.95);
  color: var(--cui-header-hover-color, rgba(44, 56, 74, 0.95));
}

.help-page .accordion-item {
  border: 0;
}

.help-page .accordion-header {
  font-weight: 500;
  letter-spacing: 0.03rem;
  transition: all 0.2s ease-in-out;
  padding: 0;
}

.help-page .accordion-button {
  padding: 0.75rem 0.75rem;
  box-shadow: none;
}

.help-page .accordion-button:not(.collapsed) {
  color: #3c4b64;
  background-color: #fff;
}

.help-page .accordion-button:focus {
  border: 0;
}

.help-page .accordion-body {
  padding: 0;
}

.help-page .accordion-body ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.help-page .accordion-body li {
  padding: 12px 15px;
  color: #555555;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  transition: height 0.35s ease;
  cursor: pointer;
}

.help-page .accordion-body li:hover {
  color: #212121;
  background-color: #f1f2f3;
}

.help-page .main-topic .accordion-button:after {
  display: none;
}

.help-page li {
  margin-bottom: 10px;
}

#help-container {
  position: relative;
  height: 100vh;
  overflow: scroll;
  margin-bottom: 100px;
}

#help-container h3,
#help-container p {
  padding-right: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Tooltip / Popover */
.tippy-box[data-theme~="cpopover"] {
  background: white;
  color: inherit;
  border: 1px solid rgba(0, 0, 21, 0.2);
}
.tippy-box[data-theme~="cpopover"] > .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~="cpopover"] > .tippy-arrow::before {
  color: white;
}
